import { Controller } from "@hotwired/stimulus";
import createChannel from '../cable';

export default class extends Controller {
  static targets = ['report', 'reportProgress', 'reportProgressBar'];

  connect() {
    let reportDone = 0;
    const totalReports = this.data.get('total-reports');
    let thisController = this;

    createChannel({ channel: 'CohortReportChannel', request_id: this.data.get('request') }, {
      received({ report }) {
        console.log("received data");
        thisController.reportTarget.innerHTML = report;
        thisController.initChart();
        thisController.initBarChart();
      },
    });

    createChannel({ channel: 'ReportProgressNotifierChannel', request_id: this.data.get('request') }, {
      received({ delivery_id }) {
        console.log("Received data in ReportProgressNotifierChannel");
        reportDone += 1;
        const percentage = Math.round((reportDone / totalReports) * 100);
        if (thisController.hasReportProgressBarTarget) {
          thisController.reportProgressBarTarget.style.width = `${percentage}%`;
          thisController.reportProgressBarTarget.setAttribute('aria-valuenow', percentage);
          thisController.reportProgressBarTarget.innerHTML = `${percentage}%`;
        } else {
          console.error("Report progress bar target is not defined.");
        }

        if (thisController.hasReportProgressTarget) {
          thisController.reportProgressTarget.innerHTML = `Reports calculated: ${reportDone} / ${totalReports}`;
        } else {
          console.error("Report progress target is not defined.");
        }
      },
    });
  }

  initChart() {
    google.charts.load('current', {'packages':['corechart']});
    google.charts.setOnLoadCallback(drawChart);

    function drawChart() {
      try {
        const chartDataElement = document.getElementById('piechart_0');
        if (!chartDataElement) {
          console.error("Chart element not found");
          return;
        }

        const graphData = chartDataElement.dataset.graphData;

        if (!graphData || graphData.length === 0) {
          console.error("Graph data is empty or undefined");
          return;
        }

        const dataRows = JSON.parse(graphData);

        var dataTable = new google.visualization.DataTable();
        dataTable.addColumn('string', 'level');
        dataTable.addColumn('number', 'score');
        dataTable.addColumn({type: 'string', role: 'tooltip', 'p': {'html': true}});
        dataTable.addRows(dataRows);

        var options = {
          legend: 'none',
          colors: ['#297937', '#FAA633', '#ad346A'],
          pieSliceTextStyle: {
            fontName: 'Roboto',
            fontSize: 16
          },
          pieSliceText: 'percentage',
          tooltip: {isHtml: true, trigger: 'selection'},
          backgroundColor: {
            fill: '#FFFFFF',
            fillOpacity: 0.0
          },
          chartArea: {
            height: "90%",
            width: "90%"
          }
        };

        var container = document.getElementById('piechart_0');
        var chart = new google.visualization.PieChart(container);

        google.visualization.events.addListener(chart, 'click', clearSelection);
        document.body.addEventListener('click', clearSelection, false);

        chart.draw(dataTable, options);

        function clearSelection(e) {
          if (!container.contains(e.srcElement)) {
            chart.setSelection();
          }
        }

      } catch (error) {
        console.error("Error parsing JSON data: ", error);
      }
    }
  }

  initBarChart() {
    google.charts.load('current', {'packages':['corechart']});
    google.charts.setOnLoadCallback(drawChart);

    function drawChart() {
      try {
        const chartDataElement = document.getElementById('barchart_0');
        if (!chartDataElement) {
          console.error("Bar Chart element not found");
          return;
        }

        const barChartData = chartDataElement.dataset.barChartData;

        if (!barChartData || barChartData.length === 0) {
          console.error("Bar chart data is empty or undefined");
          return;
        }

        const parsedData = JSON.parse(barChartData);

        var data = google.visualization.arrayToDataTable(parsedData);

        var options = {
          colors: ['#297937', '#FAA633', '#ad346A'],
          bar: {groupWidth: "35%"},
          vAxis: {
            title: "# of students",
            titleTextStyle: {
              fontName: 'Roboto',
              italic: false,
            }
          },
          hAxis: {
            textStyle: {fontName: 'Inter'}
          },
          backgroundColor: {
            fill: '#FFFFFF',
            fillOpacity: 0.0
          },
          chartArea: {
            height: "60%",
            width: "70%"
          },
          isStacked: true,
          legend: 'none'
        };

        var container = document.getElementById('barchart_0');
        var chart = new google.visualization.ColumnChart(container);

        chart.draw(data, options);
        window.addEventListener('resize', () => {
          chart.draw(data, options);
        });

      } catch (error) {
        console.error("Error parsing JSON data: ", error);
      }
    }
  }
}
