import { Controller } from "@hotwired/stimulus";
import React from "react";

export default class extends Controller {
  static targets = ["teachingPointModal"];

  hideTeachingPointModal(){
    this.teachingPointModalTarget.classList.add("hidden");
  }

  openTeachingPointModal(){
    window.renderAllChildReactOnRailsComponents?.(this.element);
    this.teachingPointModalTarget.classList.remove("hidden");
  }
}
