import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "modal" ];

  initialize() {
    this.handleKeyDown = this.handleKeyDown.bind(this);
    document.addEventListener("keydown", this.handleKeyDown);
  }

  disconnect() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown(event) {
    if (event.key === "Escape") {
      this.close();
    }
  }

  open(e) {
    e.preventDefault();
    e.stopImmediatePropagation();
    this.modalTarget.classList.remove("hidden");

    // Ensure that the next tick focuses the first focusable element
    setTimeout(() => {
      this.setFocusOnFirstElement();
    }, 0);
  }

  close() {
    if (this.modalTarget) {
      const subscriberForm = document.querySelector('#subscriber-form');
      if (subscriberForm){
        subscriberForm.reset();
      }

      this.modalTarget.classList.add("hidden");
    }
  }

  setFocusOnFirstElement() {
    const focusableElements = this.modalTarget.querySelectorAll('input[type="checkbox"], button, [tabindex]:not([tabindex="-1"])');
    if (focusableElements.length > 0) {
      focusableElements[0].focus();
    }
  }
}
