import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "email", "phone", "startDate", "startTime",
                     "endDate", "endTime", "programType", "examCourse",
                     "purpose", "rosterings" ]

  initialize() {
    if (!this.emailTarget) { return; }

    // Setup Start DatePicker
    $(this.startDateTarget).datepicker({
      numberOfMonths: 2,
      dateFormat: "M d, yy",
      minDate: 0,
      onSelect: (date) => { this.startDateSelected(date); this.reloadExamCourses() },
    }).on('keypress', function(e){ e.preventDefault(); })

    // Setup End DatePicker
    $(this.endDateTarget).datepicker({
      numberOfMonths: 2,
      dateFormat: "M d, yy",
      onSelect: (date) => { this.endDateSelected(date) }
    }).on('keypress', function(e){ e.preventDefault(); });

    // Initial field states
    setInactive(this.startTimeTarget);
    setInactive(this.endTimeTarget);
    setInactive(this.endDateTarget);

    // Prevent the user from being able to submit the form with Enter
    $(document).ready(function() {
      $(window).keydown(function(event){
        if(event.keyCode == 13) {
          event.preventDefault();
          return false;
        }
      });
    });
  }

  // Are we in the Calibrate Demonstration Program?
  get isDemo() {
    return JSON.parse(this.element.dataset.demo)
  }

  // Number of days between start date and end date in seconds
  get timeDiff() {
    return new Date(this.endDateTarget.value) - new Date(this.startDateTarget.value)
  }

  //////////////////////
  // Stimulus Actions //
  //////////////////////
  reloadExamCourses() {
    const url = this.examCourseTarget.dataset.url && new URL(this.examCourseTarget.dataset.url)

    if (url) {
      url.searchParams.append('start_date', this.startDateTarget.value)
    }

    fetch(url)
      .then(response => response.text())
      .then(html => {
        this.examCourseTarget.innerHTML = html
      })
  }

  startDateSelected(windowStartDate) {
    setActive(this.startTimeTarget);

    this.startDateTarget.classList.add("date-selected");
    $(this.endDateTarget).datepicker("option", "minDate", windowStartDate);
    if (this.isDemo) {
      const diff = new Date(this.endDateTarget.value) - new Date(this.startDateTarget.value)
      this.#rebuildEndTimeSelectOptions(this.timeDiff);
    } else {
      const windowEndDate = new Date(windowStartDate);
      windowEndDate.setDate(windowEndDate.getDate() + 10);
      $(this.endDateTarget).datepicker("option", "maxDate", windowEndDate);

      this.#resetEndTimeAndDateFields();
    }
  }

  startTimeSelected() {
    setActive(this.endDateTarget);
    if (this.isDemo) return
    this.#resetEndTimeAndDateFields();
  }

  endDateSelected(date) {
    setActive(this.endTimeTarget);

    this.endDateTarget.classList.add("date-selected");

    this.#rebuildEndTimeSelectOptions(this.timeDiff);
  }

  checkRosteringPresence(ev) {
    const aqueductTable = window.AqueductTable;
    const rows          = aqueductTable.state.rows;

    if (!rows.some(row => row.selected)) {
      let answer = confirm('You have not selected any students. Do you want to request the delivery without any students?')
      if (!answer) {
        ev.preventDefault()
      }
    }
  }

  checkCustomValidities() {
    this.emailTarget.setCustomValidity(
      this.emailTarget.validity.valueMissing ? "Provide contact email" : ""
    );

    this.phoneTarget.setCustomValidity(
      this.phoneTarget.validity.valueMissing ? "Provide contact phone" : ""
    );

    this.startDateTarget.setCustomValidity(
      this.startDateTarget.validity.valueMissing ? "Select start date" : ""
    );

    this.startTimeTarget.setCustomValidity(
      this.startTimeTarget.validity.valueMissing ? "Select start time" : ""
    );

    this.endDateTarget.setCustomValidity(
      this.endDateTarget.validity.valueMissing ? "Select end date" : ""
    );

    this.endTimeTarget.setCustomValidity(
      this.endTimeTarget.validity.valueMissing ? "Select end time" : ""
    );

    this.programTypeTarget.setCustomValidity(
      this.programTypeTarget.validity.valueMissing ? "Select program type" : ""
    );

    this.examCourseTarget.setCustomValidity(
      this.examCourseTarget.validity.valueMissing ? "Select course" : ""
    );

    this.purposeTarget.setCustomValidity(
      this.purposeTarget.validity.valueMissing ? "Select purpose" : ""
    );
  }

  #resetEndTimeAndDateFields() {
    this.endDateTarget.value = null;
    this.#rebuildEndTimeSelectOptions();
  }

  #rebuildEndTimeSelectOptions(diff = null) {
    const optionsArray = [...this.startTimeTarget.options]

    const appendEndTimeOptions = (startIndex, endIndex) => {
      this.endTimeTarget.options.length = 0
      for (let i = startIndex; i <= endIndex; i++) {
        appendSelectOption(this.endTimeTarget, optionsArray[i])
      }
    }

    switch(diff) {
      case 0:
        appendEndTimeOptions(this.startTimeTarget.selectedIndex, optionsArray.length-1)
        break;
      case 864000000:
        appendEndTimeOptions(0, this.startTimeTarget.selectedIndex)
        break;
      case 860400000:
        appendEndTimeOptions(0, Math.min(this.startTimeTarget.selectedIndex+1, optionsArray.length-1))
        break;
      case 867600000:
        appendEndTimeOptions(0, Math.max(this.startTimeTarget.selectedIndex-1, 1))
        break;
      default:
        appendEndTimeOptions(0, optionsArray.length-1)
    }

    function appendSelectOption(select, option) {
      const optionEl = document.createElement("option")
      optionEl.value = option.value
      optionEl.innerHTML = option.innerHTML
      select.appendChild(optionEl)
    }
  }
}

function setInactive(element) {
  element.disabled = true;
  element.style.background = "lightgray";
  element.style.color = "#a6a6a6";
}

function setActive(element) {
  element.disabled = false;
  element.style.background = "";
  element.style.color = "";
}
