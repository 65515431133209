import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggle"]

  connect() {
    if(window.location.href.split('#').pop() == 'available_content_graph') {
      document.getElementById('available_content_graph')?.scrollIntoView();
    }
  }

  play() {
    if (this.hasToggleTarget) {
      for (const target of this.toggleTargets) {
        target.classList.toggle('closed')
      }
    }
  }

  handleKeypress(event) {
    // Check if the Enter key was pressed 
    if ((event.key === 'Enter' || event.keyCode === 13) ) {
      // Call the play method
      this.play();
    }
  }
}
