import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    document.addEventListener("keyup", this.handleKeyUp);
  }

  disconnect() {
    document.removeEventListener("keyup", this.handleKeyUp);
  }

  handleKeyUp(event) {
    if (event.key === "Tab") {
      const activeElement = document.querySelector(".current-element");
      if (activeElement) {
        activeElement.classList.remove("current-element");
      }

      const focusedElement = document.activeElement;
      if (focusedElement && (
        focusedElement.tagName === "A" ||
        focusedElement.tagName === "BUTTON" ||
        focusedElement.tagName === "INPUT" ||
        focusedElement.classList.contains("nav-link")
      )) {
        focusedElement.classList.add("current-element");
      }
    }
  }
}
