import { Controller } from "@hotwired/stimulus";
import createChannel from '../cable';

// Connects to data-controller="single-cohort-report"
export default class extends Controller {
  static targets = ['report'];

  connect() {
    let thisController = this;

    createChannel({ channel: 'CohortReportChannel', request_id: this.data.get('request') }, {
      received({ report }) {
        console.log("received data");
        thisController.reportTarget.innerHTML = report;
      },
    });
  }
}
